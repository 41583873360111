@import "@/assets/scss/style.scss";

.order-form {
    &__general-info {
        display: flex;
        flex-direction: column;
        padding: 10px;
        overflow-y: auto;
        height: 65vh;
    }

    &__form-item {
        margin-bottom: 5px;

        & .ant-input-number {
            width: 100%;
        }
    }

    &__dates-container {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 2rem;
    }

    &__label {
        display: block;
        margin-bottom: 5px;
    }
}

.is-date {
    width: 50%;
}

.is-invalid {
    border-color: red !important;
}

.label-star {
    color: red;
}

::v-deep .is-date .is-invalid input {
    border-color: red;
}

::v-deep .ant-select.is-invalid > div:first-of-type {
    border-color: red;
}
