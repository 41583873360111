@import "@/assets/scss/style.scss";

.order-form {
    &__orders {
        display: flex;
        flex-direction: column;
        padding: 10px;
        overflow-y: auto;
        height: 65vh;
    }

    &__orders-container {
        margin-top: 2rem;
    }

    &__form-item {
        margin-bottom: 5px;
    }

    &__add-button {
        width: min-content;
        flex-shrink: 0;
    }

    &__order {
        display: flex;
        flex-direction: column;
        border: 1px solid #d9d9d9;
        padding: 15px;
        margin-bottom: 20px;
        border-radius: 5px;
    }

    &__delete-button {
        align-self: flex-end;
        margin-bottom: 10px;
    }
}
